<template>
    <transition appear enter-active-class="animated fadeIn">
        <div>
            <b-loading :is-full-page="true" :active="!content"/>

            <div class="level-item">
                <h1 class="title is-1 page-title">SPORTS & ACTIVITIES COVER</h1>
            </div>
            <div class="box b-shdw-3">

                <InfoButtonOptions :policyType="'ST'" :coverType="'ST'"/>

                <!-- Injected content -->
                <div class="content">
                    <h2>GOING ON A SPORTS AND ACTIVITIES HOLIDAY? DON'T FORGET TO ADD COVER FOR YOUR SPORTS AND ACTIVITIES.</h2>

                    <p>
                        For all our thrill seekers out there, we have a wide range of sports and activities covered as
                        standard so that you can thoroughly enjoy your activity holiday. Depending on what type of trip
                        you are planning, and the sporting activity you are going to be taking part in, we may able to
                        cover it for free, or for an additional premium (if your activity is more of a risk).
                    </p>

                    <h2>OUR SPORTS AND ACTIVITIES COVER INCLUDES:</h2>

                    <ul>
                        <li>Our Platinum, Gold and Silver products now include Covid-19 Cover</li>
                        <li>Over 110 sports and activities covered as standard (special conditions and exclusions may apply, see the Policy Wording for the full policy terms)</li>
                        <li>Cancellation cover up to £10,000</li>
                        <li>24-hour Emergency Assistance</li>
                        <li>Cover up to £5,000 for personal possessions</li>
                    </ul>
                </div>

                <!-- FAQs -->
                <b-collapse class="card" v-for="i in 1" :key="i" :open="false">
                    <div slot="trigger" slot-scope="props" class="card-header" role="button">
                        <p class="card-header-title" v-html="content[`faq${i}_title`]"/>
                        <a class="card-header-icon">
                            READ MORE... <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"/>
                        </a>
                    </div>
                    <div class="card-content">
                        <div class="content" v-html="content[`faq${i}_content`]"/>
                    </div>
                </b-collapse>

                <!-- Injected content -->
                <div class="content" v-html="content.other"/>

                <!-- Bottom buttons -->
                <InfoButtonOptions :policyType="'ST'" :coverType="'ST'"/>

                <br><br>
                <info-boxes/>
                <!-- <trustpilot-banner/> -->
            </div>
        </div>
    </transition>
</template>

<script>
    import InfoBoxes from "../components/common/InfoBoxes";
    // import TrustpilotBanner from "@/components/common/trustpilot/TrustpilotBanner";
    import InfoButtonOptions from "@/components/common/InfoButtonOptions";
    import { contentAPI } from "@/mixins/content-api";
    import { page } from "@/mixins/page";

    export default {
        name: "SportsActivitiesCover",
        mixins: [ contentAPI, page ],
        components: {
            InfoBoxes,
            // TrustpilotBanner,
            InfoButtonOptions
        }
    }
</script>

<style lang="scss" scoped>
    @import "src/styles/views/info-page";
</style>